// ***** color ******
$main: #004f9d;
$blue: #3c7ac3;
$blue2: #0038ef;
$blue3: #0068b7;
$blue-l: #ebf1f9;
$red: #da3a42;
$red2: #FF0000;
$gray: #ebf1f9;
$gray2: #fbfbfb;
$black: #393939;
$yellow: #f7b200;
$yellow-l: #ffeb36;
$yellow-l2: #fff8dd;
$orange: #ff6e21;
$label-chip-required: #F77C00;
$label-chip-any: #999999;
$white: #ffffff;
$black: #393939;

// ***** gradation ******
$gradOrange: linear-gradient(180deg, #f7ccb5 0%, #ff8d50 9.38%, #ff6e21 100%);
$gradGray: linear-gradient(180deg, #f2f2f2 0%, #ffffff 7.81%, #e8e6e5 100%);
$gradGeen: linear-gradient(180deg, #9deec7 0%, #45ca8a 8.33%, #0d9b57 100%);
$gradRed2: linear-gradient(180deg, #eb243c 0%, #e36f84 47.4%, #d91e1e 100%);

// ***** background color ******
$bg: #fdfdfd;
$bg-y: #fff8dd;
$bg-r: #da3a42;
$bg-g: #9deec7;
$bg-g2: #00A63B;
$bg-b: rgba(0, 0, 0, 0.55);
// ***** font family ******
$basefont: "Noto Sans JP", "Lato", "Yu Gothic", YuGothic, -apple-system, BlinkMacSystemFont, メイリオ, Meiryo, Helvetica, sans-serif;
$en-font: "Lato", "Helvetica neue", sans-serif;
$mincho: "Noto Serif JP", serif;

// ***** hover ******
$ho-r: #cb363d;
$ho-w:#ebf1f9;
$ho-g:#05b665
